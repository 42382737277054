import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TermineMargin from "../../components/termine-margin"
import ElternInfoContentHeader from "../../components/elterninfo/elterninfo_contentHeader"
import ElternInfoSubmenu from "../../components/elterninfo/elterninfo_submenu"
import TermineComp from "../../components/termine/termine-table"

const termine = () => (
  <Layout>
    <SEO title="Aktuelle Termine"/>

    <section id="content_2_schule">
      <ElternInfoContentHeader headline="AKTUELLE TERMINE" motto="ELTERN-INFORMATIONEN"/>
      <ElternInfoSubmenu/>

      <article id="con_2_rechts">
        <h3 className="small_sub"><span style={{ color: "#007c34" }}>AKTUELLE TERMINE</span></h3>
        <div id="allTermine">
          <TermineComp />
        </div>

      </article>

      <TermineMargin/>
    </section>
  </Layout>
)

export default termine