import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import TermineTableEntry from "./termine-table-entry"
import TermineTableEntries from "./termine-table-entries"

const TableComponents = () => {

  const data = useStaticQuery(graphql`
     query terminQueryAndTerminQuery {
        allSanityTermin(sort: {fields: date, order: ASC}) {
          nodes {
            title
            description
            month: date(locale: "de", formatString: "MMMM")
            date(locale: "de", formatString: "DD.MM.YYYY")
          }
        }
     }  
  `)

  const buildTable = () => {
    let lastMonth = ""
    const tableComps = []
    const monthsTables = {}

    let termineNodes = data.allSanityTermin.nodes

    for (let [index, val] of termineNodes.entries()) {
      let actMonthComps = monthsTables[val.month]
      if (!actMonthComps) {
        actMonthComps = [];
        monthsTables[val.month] = actMonthComps;
      }
      actMonthComps.push(<TermineTableEntry title={val.title} description={val.description} dateStr={val.date}/>)
    }

    for(var key in monthsTables) {
      var actMonthComps = monthsTables[key];
      tableComps.push(<TermineTableEntries actMonthTableEntries={actMonthComps} actMonth={key}/>)
    }

    return tableComps
  }

  return buildTable()
}

export default TableComponents