import React from 'react'
import * as termineStyles from "./termine-table-entries.module.css"

export default ({actMonthTableEntries, actMonth}) => (
  <div className={termineStyles.termineTable} key={actMonth}>
    <h4 className='xsmall_sub'>{actMonth}</h4>
    <table width='638' border='1' cellPadding='5' cellSpacing='0' className='text'>
      <tbody>
      {actMonthTableEntries.map(tableEntry => tableEntry)}
      </tbody>
    </table>
  </div>
)
